import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from '../shared/components/header/header.component';
import { FooterComponent } from '../shared/components/footer/footer.component';
import { environment } from 'src/environments/environment';
import { GetCountryDataService } from 'src/shared/services/get-country-data.service';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, HeaderComponent, FooterComponent, CommonModule],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
    hideHeaderAndFooter = false;
    getCountryData = inject(GetCountryDataService);
    async ngOnInit() {
        // this.getCountryData.saveDataWithOutLogin();
        // await this.initializeApp();
        // this.hideHeaderAndFooter =
        this.hideHeaderAndFooter = (environment as any).hideHeaderAndFooter
            ? (environment as any).hideHeaderAndFooter
            : false;
    }
    async initializeApp() {
        if (environment.apigoogle) {
            const scriptEl = window.document.createElement('script');
            scriptEl.async = true;
            scriptEl.src =
                'https://maps.googleapis.com/maps/api/js?key=' +
                environment.apigoogle +
                `&libraries=places,marker,geometry&language=ES&region=GT`;
            window.document.head.appendChild(scriptEl);
        }
    }
}
