<header>
    <nav
        class="navbar navbar-expand-lg navbar-light bg-white fixed-top shadow-sm">
        <div class="container-fluid p-0 align-items-stretch d-flex">
            <!-- Logo -->
            <a
                href="https://forzadelivery.com"
                class="d-flex align-items-center">
                <img
                    width="160"
                    height="33"
                    src="assets/logos/logo.png"
                    class="imageLogo"
                    alt="Logo Forza" />
            </a>

            <!-- Botón de menú colapsable en vista móvil -->
            <button
                class="navbar-toggler toggle-button d-lg-none"
                [ngClass]="{
                    'close-icon': isCollapsed,
                    'open-icon': !isCollapsed,
                }"
                type="button"
                (click)="toggleNavbar()"
                aria-controls="navbarText"
                aria-expanded="!isCollapsed"
                aria-label="Toggle navigation">
                <i class="bi bi-list" *ngIf="isCollapsed"></i>
                <i class="bi bi-x-lg" *ngIf="!isCollapsed"></i>
            </button>

            <!-- Menú colapsable -->
            <div
                class="collapse navbar-collapse pl-0 pr-0 pt-0 mt-0 align-items-stretch"
                id="navbarText"
                [class.show]="!isCollapsed">
                <ul class="navbar-nav ml-auto align-items-center">
                    <!-- Otros enlaces de navegación -->
                    <li class="nav-item d-flex align-items-center">
                        <a
                            class="nav-link-custom"
                            href="https://forzadelivery.com"
                            target="_blank"
                            >Inicio</a
                        >
                    </li>
                    <li class="nav-item d-flex align-items-center">
                        <a
                            class="nav-link-custom"
                            href="https://portal.forzadelivery.com"
                            target="_blank"
                            ><i class="fa-solid fa-user mr-1"></i>Portal</a
                        >
                    </li>
                    <li class="nav-item d-flex align-items-center">
                        <a
                            [href]="
                                'https://tienda.forzadelivery.com/inicio/' +
                                countrySelect.nationalityAccount
                            "
                            target="_blank"
                            class="nav-link-custom"
                            ><i class="fa-solid fa-store mr-1"></i>Tienda
                            Virtual</a
                        >
                    </li>
                    <li class="nav-item d-flex align-items-center">
                        <a
                            class="nav-link-custom"
                            href="https://rastreo.forzadelivery.com/login"
                            target="_blank"
                            >Rastreo</a
                        >
                    </li>
                    <li class="nav-item d-flex align-items-center">
                        <a
                            class="nav-link-custom"
                            href="https://cotizador.forzadelivery.com"
                            target="_blank"
                            ><i class="fa-solid fa-calculator mr-1"></i
                            >Cotizar</a
                        >
                    </li>
                    <!-- Elemento dropdown para selección de país -->
                    <li
                        class="nav-item dropdown align-items-center"
                        *ngIf="showSelect">
                        <a
                            class="dropdown-toggle nav-link-custom d-flex align-items-center"
                            href="#"
                            id="countrySelectDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                            <ng-container *ngIf="countrySelect">
                                <em
                                    [class]="countrySelect.icon"
                                    style="
                                        width: 21px !important;
                                        margin-right: 8px;
                                    "></em>
                                <span>{{ countrySelect?.text }}</span>
                            </ng-container>
                        </a>
                        <div
                            class="dropdown-menu"
                            aria-labelledby="countrySelectDropdown">
                            <ng-container *ngFor="let item of countries">
                                <a
                                    class="dropdown-item d-flex align-items-center"
                                    href="#"
                                    (click)="selectCountry(item)">
                                    <em
                                        [class]="item.icon"
                                        style="
                                            width: 21px !important;
                                            margin-right: 8px;
                                        "></em>
                                    <span>{{ item.text }}</span>
                                </a>
                            </ng-container>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>
